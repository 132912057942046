<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"            
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="25vw"
            :visible="showFilter"
            @on-item-search="
              showFilter = false;
              reset();
            "
            @on-item-reset="
              showFilter = false;
              reset(true);
            "
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-1">
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.account_id"
                  :busy="busy"
                  label="Account ID"
                  :hasClear="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.account_name"
                  :busy="busy"
                  label="Name"
                  :hasClear="true"
                />
              </v-col> 
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.active"
                  :busy="busy"
                  label="Active"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col> 
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider v-if="action === ''" />

      <v-card-text v-if="action === ''" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="[
            {
              text: '#',
              align: 'end',
              value: 'row_no',
              type: 'int',
              width: 100,
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'Platform',
              align: 'start',
              sortable: false,
              value: 'platform_name',
              type: 'slot',
            },
            {
              text: 'Active',
              align: 'start',
              value: 'active',
              type: 'boolean',
            },
            { text: 'Locale', align: 'start', value: 'locale', type: 'text', width: 100 },
            {
              text: 'Timezone',
              align: 'start',
              value: 'timezone',
              type: 'text',
            },
            { text: 'Notes', align: 'start', value: 'notes', type: 'text' },
            {
              text: 'Created Date',
              align: 'start',
              value: 'created_at',
              type: 'date-time',
            },
            {
              text: 'Created By',
              align: 'start',
              value: 'created_by_name',
              type: 'slot',
            },
            {
              value: 'action',
              type: 'action',
              width: 100,
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Edit',
                  icon: 'mdi-pencil-box-outline',
                  color: 'success',
                },
                {
                  key: 'on-item-delete',
                  label: 'Delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'danger',
                },
              ],
            },
            {
              text: 'Deleted Date',
              align: 'start',
              value: 'deleted_at',
              type: 'date-time',
            },
            {
              text: 'Deleted By',
              align: 'start',
              value: 'deleted_by_name',
              type: 'slot',
            },
          ]"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'platform_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.platform_id > 0">
                {{ row.prop.item.platform_name }} ({{
                  row.prop.item.platform_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'created_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'deleted_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ''" />

      <div v-if="action !== ''" class="ml-5 pb-5 mr-5">
        <v-row class="o-container-content">
          <v-col cols="12" class="pa-10 pr-15">
            <v-form ref="form" v-model="valid" lazy-validation>
              <o-input
                v-model="model.account_name"
                :busy="busy"
                label="Account Name"
                :is-required="true"
                :rules="[(v) => !!v || 'This is required']"
              />

              <o-check-box v-model="model.active" :busy="busy" label="Active" />

              <o-check-box
                v-model="model.primary_account"
                :busy="busy"
                label="Primary Account"
              />

              <o-input
                v-model="model.locale"
                :busy="busy"
                label="Locale"
                class="mt-4"
              />

              <o-combo-box
                v-model="model.timezone"
                :has-id="false"
                :busy="busy"
                label="Timezone"
                :items="timezones"
              />

              <o-input
                v-model="model.date_format_string"
                :busy="busy"
                label="Date Format"
              />

              <o-combo-box
                v-model="model.platform_id"
                :busy="busy"
                label="DSP Platform"
                :items="platforms"
              />

              <o-input
                v-model="model.notes"
                :busy="busy"
                label="Notes"
                type="textarea"
              />
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "ManageAccount",
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Accounts",
  },
  data() {
    return {
      title: "Accounts",
      busy: false,
      action: "",
      valid: false,
      parameters: {
        account_id: "", 
        account_name: "",  
        active: []
      },
      baseModel: {
        id: 0,
        customer_id: "",
        account_name: "",
        primary_account: false,
        locale: "en_US",
        date_format_string: "Y-m-d H:i:s",
        timezone: "America/New_York",
        account_id: 0,
        notes: "",
        active: false,
        platform_id: 1,
        created_by: 0,
        updated_by: 0,
      },
      model: {},
      originalModel: {},
      platforms: [],
      timezones: [],
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  watch: {},
  created() {
    this.listParameters.fields = [
      "ROW_NUMBER() OVER(ORDER BY a.deleted_at DESC, a.updated_at DESC) row_no",
      "a.*",
      "b.platform_name",
      "concat(c.last_name, ', ', c.first_name) as created_by_name",
      "concat(d.last_name, ', ', d.first_name) as deleted_by_name",
    ];
    this.listParameters.sources = {
      main: "accounts a",
      children: [
        {
          name: "dsp_platforms b",
          on: "a.platform_id = b.id",
        },
        {
          name: "users c",
          on: "a.created_by = c.id",
        },
        {
          name: "users d",
          on: "a.deleted_by = d.id",
        },
      ],
    };
    this.listParameters.orders = [
      {
        sort: "a.deleted_at",
        dir: "DESC",
      },
      {
        sort: "a.updated_at",
        dir: "DESC",
      },
    ];
  },
  async mounted() { 
    this.platforms = await this.getList(
      ["id, platform_name as name"],
      "dsp_platforms",
      [],
      [],
      "name"
    );
    this.timezones = await this.getList(
      ["name as id, name"],
      "pg_timezone_names() ",
      [
        ` (
        name like 'America%'
        or name like 'India%'
        or name like 'Europe%' 
        or name like 'Canada%'
      )`,
      ],
      [],
      "name"
    );
    for (const [k, v] of Object.entries(this.parameters)) { 
      if (this.$route.query[k]) {
        this.parameters[k] = this.$route.query[k];
      }
    }     
    const wheres = this.setParameters();
    if (wheres.length > 0)
      this.listParameters.wheres = [
        wheres.join(` AND `),
      ];
  },
  methods: {
    add() {
      this.action = "add";
      this.model = _.cloneDeep(this.baseModel);
    },
    edit(item) {
      this.model = _.cloneDeep(item);
      this.originalModel = _.cloneDeep(item);
      this.action = "edit";
    },
    async remove(item) {
      this.model = item;
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are deleting <strong>${this.model.account_name} (${this.model.account_id})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            this.model.deleted_by = this.loggedInUser.id;
            await this.deleteData(`/rest/accounts/${this.model.id}`, null)
              .then((response) => {
                this.$refs.container.snackBar(
                  `
                The record of ${this.model.account_name} (${this.model.account_id}) was successfully deleted.
                `,
                  "red",
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `
                Error updating item: ${errors.join(",")}
                `,
                  "red",
                  2000
                );
              });
          }
        });
    },
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = "/rest/accounts";
      const model = _.cloneDeep(this.model);
      if (this.action === "add") {
        this.model.created_by = this.loggedInUser.id;
        await this.postData("/rest/accounts", this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully added ${model.account_name} (${model.account_id}).
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {
        this.model.updated_by = this.loggedInUser.id;
        const model = _.cloneDeep(this.model);

        if (this.model.account_name === this.originalModel.account_name) {
          delete this.model.account_name;
        }
        await this.putData(`/rest/accounts/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully updated ${model.account_name} (${model.account_id}).
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `
                Error updating item: ${errors.join(",")}
                `,
          "red",
          2000
        );
      } else {
        this.reset();
      }
    },
    async reset(clear) {
      await this.clearQueryString();
      if (clear) {
        this.parameters = { 
          account_id: "", 
          account_name: "",  
          active: []
        }
      }
      this.action = "";
      this.model = _.cloneDeep(this.baseModel);

      this.listParameters.wheres = []; 

      const wheres = this.setParameters();
      if (wheres.length > 0)
        this.listParameters.wheres = [
          wheres.join(` AND `),
        ];

      await this.changeQueryString(this.parameters);

      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
    checkName(value) {
      let filter = `/findByName?account_name=${value}`;
      AccountsService.getResourceByFilter(filter)
        .then((response) => {
          this.errorExist = response.data ? "Name already exist" : "";
          this.rules.exist = !response.data;
        })
        .catch((err) => {
          this.snackbar_show = true;
          this.snackbar_message = "Error checking item: " + err;
        });
    },
    setParameters() {
      const wheres = [];

      if (this.parameters.account_id.length > 0) { 
        wheres.push(
          `a.account_id in (${this.stringToStringArray(this.parameters.account_id).join(
            `, `
          )})`
        );
      }
      if (this.parameters.active.length > 0) {
        wheres.push(
          `a.active in (${this.stringToStringArray(this.parameters.active).join(
            `, `
          )})`
        );
      }
      if (this.parameters.account_name != ``) {
          wheres.push(
          this.getWhereString(`a.account_name`, this.parameters.account_name)
        );
      } 
      
      return wheres;
    }
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>

<template>
  <ManageAccount dialog-name="Account" />
</template>
<script>
import ManageAccount from '@/views/app/manage/Account'

export default {
  name: 'AccountWrapper',
  components: {
    ManageAccount
  }
}
</script>
